<template>
  <ion-page>
    <ion-header collapse="fade" class="ion-no-border">
      <nav-bar-guests></nav-bar-guests>
    </ion-header>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <div class="nk-main">
        <div class="nk-wrap nk-wrap-nosidebar">
          <div class="nk-content">
            <div class="nk-block nk-block-middle nk-auth-body wide-xs">
              <div class="brand-logo pb-5" v-if="!$isHybridPlatform">
                <brand-logo></brand-logo>
              </div>
              <div class="nk-block-head">
                <div class="nk-block-head-content">
                  <h5 class="nk-block-title">{{$t('Administrator')}} {{$t('Sign-In')}}</h5>
                  <div class="nk-block-des">
                    <p>{{ $t('login_page.sign_in_admin_message')}}</p>
                  </div>
                </div>
              </div><!-- .nk-block-head -->

              <form v-on:submit.prevent="doLogin(user_email, user_pwd)">
                <form-group>
                  <div class="form-label-group">
                    <label class="form-label" for="default-01">{{$t('Email')}}</label>
                  </div>
                  <input type="text" class="form-control form-control-lg" id="default-01" :placeholder="$t('login_page.enter_email')" v-model="user_email">
                </form-group>
                <form-group>
                  <div class="form-label-group">
                    <label class="form-label" for="password">{{$t('Password')}}</label>
                    <router-link :to="{name: 'ForgotPassword'}" class="link link-primary link-sm" tabindex="-1">{{$t('Forgot Code?')}}</router-link>
                  </div>
                  <div class="form-control-wrap">
                    <a tabindex="-1" href="javascript:;" class="form-icon form-icon-right passcode-switch" v-on:click="showPwd=!showPwd">
                      <nio-icon icon="ni ni-eye" v-if="!showPwd"></nio-icon>
                      <nio-icon icon="ni-eye-off" v-else></nio-icon>
                    </a>
                    <input :type="showPwd ? 'text' : 'password'" class="form-control form-control-lg" id="password" :placeholder="$t('login_page.enter_password')" v-model="user_pwd">
                  </div>
                </form-group>
                <form-group>
                  <form-submit-button :text="$t('Login')"></form-submit-button>
                </form-group>
              </form><!-- form -->

            </div><!-- .nk-block -->
            <auth-footer full-width></auth-footer>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader } from "@ionic/vue"
import { BrandLogo } from "@core/components"
import { FormGroup } from "@core/layouts"
import FormSubmitButton from "@/views/auth/components/FormSubmitButton";
import store from "@/store"
import {ref, defineComponent} from "vue";
import NioIcon from "@core/components/nio-icon/NioIcon";
import AuthFooter from "@/views/auth/components/AuthFooter";
import authFunctions from "@/views/auth";
import useCommonFunc from "@core/comp-functions/common"
import NavBarGuests from "@core/layouts/app-nav-bar/mobile/NavBarGuests";

export default defineComponent({
  components: {NavBarGuests, AuthFooter, NioIcon, FormSubmitButton, BrandLogo, FormGroup, IonPage, IonContent, IonHeader},
  setup(){

    let user_email = ref('')
    let user_pwd   = ref('')
    let showPwd    = ref(false)

    const {doLogin} = authFunctions()
    const {changeLang} = useCommonFunc()

    return {
      doLogin,
      user_email,
      user_pwd,
      showPwd,
      changeLang,
      store,
    }
  },
})
</script>
